@font-face {
  font-family: star;
  src: url(../fonts/star.eot);
  src: url(../fonts/star.eot?#iefix) format("embedded-opentype"), url(../fonts/star.woff) format("woff"), url(../fonts/star.ttf) format("truetype"), url(../fonts/star.svg#star) format("svg");
  font-weight: 400;
  font-style: normal;
}

.price ins {
  font-weight: 400;
}

.woocommerce-active .site-header .col-full {
  position: relative;
}

.woocommerce-active .site-branding {
  float: left;
}

.site-header-cart {
  list-style-type: none;
  margin-left: 0;
  padding: 0;
  margin-bottom: 0;
}

.site-header-cart .cart-contents {
  display: none;
}

.site-header-cart .widget_shopping_cart {
  display: none;
}

.site-header-cart .widget_shopping_cart .woocommerce-mini-cart__empty-message {
  margin: 1.41575em;
}

.site-header-cart .widget_shopping_cart .product_list_widget img {
  margin-left: 1em;
}

.site-search {
  font-size: .875em;
  clear: both;
  display: none;
}

.site-search .widget_product_search input[type=search],
.site-search .widget_product_search input[type=text] {
  padding: 1em 1.41575em;
  line-height: 1;
}

.site-search .widget_product_search form:before {
  top: 1.15em;
  left: 1.15em;
}

.site-search .widget_product_search #searchsubmit {
  clip: rect(1px 1px 1px 1px);
  clip: rect(1px, 1px, 1px, 1px);
  position: absolute !important;
}

.storefront-handheld-footer-bar {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  border-top: 1px solid rgba(255, 255, 255, 0.2);
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.7);
  z-index: 9999;
}

.storefront-handheld-footer-bar:after,
.storefront-handheld-footer-bar:before {
  content: "";
  display: table;
}

.storefront-handheld-footer-bar:after {
  clear: both;
}

.storefront-handheld-footer-bar ul {
  margin: 0;
}

.storefront-handheld-footer-bar ul li {
  display: inline-block;
  float: left;
  text-align: center;
}

.storefront-handheld-footer-bar ul li:last-child > a {
  border-right: 0;
}

.storefront-handheld-footer-bar ul li > a {
  height: 4.235801032em;
  display: block;
  position: relative;
  text-indent: -9999px;
  z-index: 999;
  border-right: 1px solid rgba(255, 255, 255, 0.2);
}

.storefront-handheld-footer-bar ul li > a:active,
.storefront-handheld-footer-bar ul li > a:focus {
  outline: 0;
}

.storefront-handheld-footer-bar ul li.search .site-search {
  position: absolute;
  bottom: -2em;
  left: 0;
  right: 0;
  -webkit-transition: all, ease, .2s;
  transition: all, ease, .2s;
  padding: 1em;
  z-index: 1;
  display: block;
}

.storefront-handheld-footer-bar ul li.search.active .site-search {
  bottom: 100%;
}

.storefront-handheld-footer-bar ul li.search .site-search,
.storefront-handheld-footer-bar ul li.search .widget,
.storefront-handheld-footer-bar ul li.search form {
  margin-bottom: 0;
}

.storefront-handheld-footer-bar ul li.cart .count {
  text-indent: 0;
  display: block;
  width: 2em;
  height: 2em;
  line-height: 2;
  box-sizing: content-box;
  font-size: .75em;
  position: absolute;
  top: .875em;
  left: 50%;
  border-radius: 100%;
  border: 1px solid;
}

.storefront-handheld-footer-bar ul.columns-1 li {
  width: 100%;
  display: block;
  float: none;
}

.storefront-handheld-footer-bar ul.columns-2 li {
  width: 50%;
}

.storefront-handheld-footer-bar ul.columns-3 li {
  width: 33.33333%;
}

.storefront-handheld-footer-bar ul.columns-4 li {
  width: 25%;
}

.storefront-handheld-footer-bar ul.columns-5 li {
  width: 20%;
}

table.shop_table_responsive thead {
  display: none;
}

table.shop_table_responsive tbody th {
  display: none;
}

table.shop_table_responsive tr td {
  display: block;
  text-align: right;
  clear: both;
}

table.shop_table_responsive tr td:before {
  content: attr(data-title) ": ";
  font-weight: 600;
  float: left;
}

table.shop_table_responsive tr td.product-remove a {
  text-align: left;
}

table.shop_table_responsive tr td.product-remove:before {
  display: none;
}

table.shop_table_responsive tr td.actions:before,
table.shop_table_responsive tr td.download-actions:before {
  display: none;
}

table.shop_table_responsive tr td.download-actions .button {
  display: block;
  text-align: center;
}

table.shop_table_responsive.my_account_orders .order-actions {
  text-align: right;
}

table.shop_table_responsive.my_account_orders .order-actions:before {
  display: none;
}

ul.products {
  margin-left: 0;
  margin-bottom: 0;
  clear: both;
}

ul.products:after,
ul.products:before {
  content: "";
  display: table;
}

ul.products:after {
  clear: both;
}

ul.products li.product {
  list-style: none;
  margin-left: 0;
  margin-bottom: 4.235801032em;
  text-align: center;
  position: relative;
}

ul.products li.product .star-rating {
  margin: 0 auto .5407911001em;
}

ul.products li.product .woocommerce-LoopProduct-link {
  display: block;
}

ul.products li.product .price {
  display: block;
  color: #43454b;
  font-weight: 400;
  margin-bottom: 1rem;
}

ul.products li.product .price ins {
  margin-left: .6180469716em;
  background-color: transparent;
}

ul.products li.product .woocommerce-loop-product__title,
ul.products li.product h2,
ul.products li.product h3 {
  font-size: 1rem;
  font-weight: 400;
  margin-bottom: .5407911001em;
}

ul.products li.product .star-rating {
  font-size: .875em;
}

ul.products li.product img {
  display: block;
  margin: 0 auto 1.618em;
}

ul.products li.product.product-category .woocommerce-loop-category__title,
ul.products li.product.product-category h2,
ul.products li.product.product-category h3 {
  font-size: 1.1em;
}

ul.products li.product.product-category img {
  margin-bottom: 1.618em;
}

ul.products li.product .button {
  margin-bottom: .236em;
}

.price del {
  opacity: .5;
  font-weight: 400;
}

.price del + ins {
  margin-left: .327em;
}

.single-product .pswp__button {
  background-color: transparent;
}

.single-product div.product {
  position: relative;
  overflow: hidden;
}

.single-product div.product:after,
.single-product div.product:before {
  content: "";
  display: table;
}

.single-product div.product:after {
  clear: both;
}

.single-product div.product .images,
.single-product div.product .summary,
.single-product div.product .woocommerce-product-gallery {
  margin-bottom: 2.617924em;
  margin-top: 0;
}

.single-product div.product .woocommerce-product-gallery {
  position: relative;
}

.single-product div.product .woocommerce-product-gallery .zoomImg {
  background-color: #fff;
}

.single-product div.product .woocommerce-product-gallery .woocommerce-product-gallery__trigger {
  position: absolute;
  top: .875em;
  right: .875em;
  display: block;
  height: 2em;
  width: 2em;
  border-radius: 3px;
  z-index: 99;
  text-align: center;
}

.single-product div.product .woocommerce-product-gallery img {
  margin: 0;
}

.single-product div.product .woocommerce-product-gallery .flex-viewport {
  margin-bottom: 1.618em;
}

.single-product div.product .woocommerce-product-gallery .flex-control-thumbs {
  margin: 0;
  padding: 0;
}

.single-product div.product .woocommerce-product-gallery .flex-control-thumbs:after,
.single-product div.product .woocommerce-product-gallery .flex-control-thumbs:before {
  content: "";
  display: table;
}

.single-product div.product .woocommerce-product-gallery .flex-control-thumbs:after {
  clear: both;
}

.single-product div.product .woocommerce-product-gallery .flex-control-thumbs li {
  list-style: none;
  margin-bottom: 1.618em;
  cursor: pointer;
}

.single-product div.product .woocommerce-product-gallery .flex-control-thumbs li img {
  opacity: .5;
  -webkit-transition: all, ease, .2s;
  transition: all, ease, .2s;
}

.single-product div.product .woocommerce-product-gallery .flex-control-thumbs li img.flex-active {
  opacity: 1;
}

.single-product div.product .woocommerce-product-gallery .flex-control-thumbs li:hover img {
  opacity: 1;
}

.single-product div.product .woocommerce-product-gallery.woocommerce-product-gallery--columns-2 .flex-control-thumbs li {
  width: 42.8571428571%;
  float: left;
  margin-right: 14.2857142857%;
}

.single-product div.product .woocommerce-product-gallery.woocommerce-product-gallery--columns-2 .flex-control-thumbs li:nth-child(2n) {
  margin-right: 0;
}

.single-product div.product .woocommerce-product-gallery.woocommerce-product-gallery--columns-2 .flex-control-thumbs li:nth-child(2n+1) {
  clear: both;
}

.single-product div.product .woocommerce-product-gallery.woocommerce-product-gallery--columns-3 .flex-control-thumbs li {
  width: 23.8095238%;
  float: left;
  margin-right: 14.2857142857%;
}

.single-product div.product .woocommerce-product-gallery.woocommerce-product-gallery--columns-3 .flex-control-thumbs li:nth-child(3n) {
  margin-right: 0;
}

.single-product div.product .woocommerce-product-gallery.woocommerce-product-gallery--columns-3 .flex-control-thumbs li:nth-child(3n+1) {
  clear: both;
}

.single-product div.product .woocommerce-product-gallery.woocommerce-product-gallery--columns-4 .flex-control-thumbs li {
  width: 14.2857142857%;
  float: left;
  margin-right: 14.2857142857%;
}

.single-product div.product .woocommerce-product-gallery.woocommerce-product-gallery--columns-4 .flex-control-thumbs li:nth-child(4n) {
  margin-right: 0;
}

.single-product div.product .woocommerce-product-gallery.woocommerce-product-gallery--columns-4 .flex-control-thumbs li:nth-child(4n+1) {
  clear: both;
}

.single-product div.product .woocommerce-product-gallery.woocommerce-product-gallery--columns-5 .flex-control-thumbs li {
  width: 8.5714285714%;
  float: left;
  margin-right: 14.2857142857%;
}

.single-product div.product .woocommerce-product-gallery.woocommerce-product-gallery--columns-5 .flex-control-thumbs li:nth-child(5n) {
  margin-right: 0;
}

.single-product div.product .woocommerce-product-gallery.woocommerce-product-gallery--columns-5 .flex-control-thumbs li:nth-child(5n+1) {
  clear: both;
}

.single-product div.product .images .woocommerce-main-image {
  margin-bottom: 1.618em;
  display: block;
}

.single-product div.product .images .thumbnails a.zoom {
  display: block;
  width: 22.05%;
  margin-right: 3.8%;
  float: left;
  margin-bottom: 1em;
}

.single-product div.product .images .thumbnails a.zoom.last {
  margin-right: 0;
}

.single-product div.product .images .thumbnails a.zoom.first {
  clear: both;
}

.single-product div.product form.cart {
  margin-bottom: 1.618em;
  padding: 1em 0;
}

.single-product div.product form.cart:after,
.single-product div.product form.cart:before {
  content: "";
  display: table;
}

.single-product div.product form.cart:after {
  clear: both;
}

.single-product div.product form.cart .quantity {
  float: left;
  margin-right: .875em;
}

.single-product div.product p.price {
  font-size: 1.41575em;
  margin: 1.41575em 0;
}

.single-product div.product table.variations {
  margin: 0;
}

.single-product div.product table.variations td,
.single-product div.product table.variations th {
  display: list-item;
  padding: 0;
  list-style: none;
  background-color: transparent;
}

.single-product div.product table.variations .value {
  margin-bottom: 1em;
}

.single-product div.product .single_variation .price {
  margin-bottom: 1em;
  display: block;
}

.single-product div.product .variations_button {
  padding-top: 1em;
}

.single-product div.product .variations_button:after,
.single-product div.product .variations_button:before {
  content: "";
  display: table;
}

.single-product div.product .variations_button:after {
  clear: both;
}

.single-product div.product .woocommerce-product-rating {
  margin-bottom: 1.618em;
  margin-top: -.875em;
}

.single-product div.product .woocommerce-product-rating:after,
.single-product div.product .woocommerce-product-rating:before {
  content: "";
  display: table;
}

.single-product div.product .woocommerce-product-rating:after {
  clear: both;
}

.single-product div.product .woocommerce-product-rating a {
  font-weight: 400;
  text-decoration: underline;
}

.single-product div.product .woocommerce-product-rating a:hover {
  text-decoration: none;
}

.single-product div.product .woocommerce-product-rating .star-rating {
  float: left;
  margin-right: .6180469716em;
}

.single-product div.product .product_meta {
  font-size: .875em;
  padding-top: 1em;
  border-top: 1px solid rgba(0, 0, 0, 0.05);
}

.single-product div.product .product_meta .posted_in,
.single-product div.product .product_meta .sku_wrapper,
.single-product div.product .product_meta .tagged_as {
  display: block;
}

.single-product div.product .product_meta .posted_in:last-child,
.single-product div.product .product_meta .sku_wrapper:last-child,
.single-product div.product .product_meta .tagged_as:last-child {
  border-bottom: 0;
}

.single-product div.product .product_meta a {
  font-weight: 400;
  text-decoration: underline;
}

.single-product div.product .product_meta a:hover {
  text-decoration: none;
}

.stock:empty:before {
  display: none;
}

.stock.in-stock {
  color: #0f834d;
}

.stock.out-of-stock {
  color: #e2401c;
}

a.reset_variations {
  display: inline-block;
  margin-left: 1em;
}

#reviews {
  outline: 0;
}

#reviews #comments {
  padding-top: 0;
}

#reviews .comment-reply-title {
  display: none;
}

#reviews .commentlist {
  list-style: none;
  margin-left: 0;
}

#reviews .commentlist li {
  margin-bottom: 2.617924em;
  list-style: none;
  clear: both;
}

#reviews .commentlist li:after,
#reviews .commentlist li:before {
  content: "";
  display: table;
}

#reviews .commentlist li:after {
  clear: both;
}

#reviews .commentlist li .comment_container {
  border-radius: 3px;
}

#reviews .commentlist li .comment_container:after,
#reviews .commentlist li .comment_container:before {
  content: "";
  display: table;
}

#reviews .commentlist li .comment_container:after {
  clear: both;
}

#reviews .commentlist li .comment_container .comment-text {
  width: 81.8181818182%;
  float: right;
  margin-right: 0;
}

#reviews .commentlist li .comment_container .comment-text .star-rating {
  float: right;
  font-size: .875em;
  line-height: 1;
  height: 1em;
}

#reviews .commentlist li:last-child {
  border-bottom: 0;
  margin-bottom: 0;
}

#reviews .commentlist li time {
  opacity: .5;
  font-size: .875em;
}

#reviews .commentlist li .avatar {
  width: 9.0909090909%;
  float: left;
  margin-right: 9.0909090909%;
  height: auto;
}

#reviews .commentlist li p.meta {
  margin-bottom: 1em;
}

#reviews .commentlist li p.meta strong {
  line-height: 1;
}

#reviews .commentlist li p.meta time {
  display: block;
}

#reviews .commentlist li p.meta .verified {
  font-style: normal;
  font-size: .875em;
}

#reviews .commentlist li p.meta .woocommerce-review__dash {
  display: none;
}

#reviews .commentlist li ul.children {
  margin: 0;
  width: 81.8181818182%;
  float: right;
  margin-right: 0;
  padding-top: 1.618em;
}

#reviews .commentlist li ul.children .avatar {
  width: 11.1111111111%;
  float: left;
  margin-right: 11.1111111111%;
}

#reviews .commentlist li ul.children .comment-text {
  width: 77.7777777778%;
  float: right;
  margin-right: 0;
}

#reviews .commentlist li ul.children li {
  margin-bottom: 1.618em;
}

#reviews .commentlist li ul.children li:last-child {
  margin-bottom: 0;
}

#reviews .comment-form-rating {
  clear: both;
}

#reviews .comment-form-rating label {
  display: block;
}

.woocommerce-breadcrumb {
  margin-left: -1.387rem;
  margin-right: -1.387rem;
  margin-bottom: 1.618em;
  font-size: .875em;
  padding: 1em 1.387rem;
}

.woocommerce-breadcrumb .breadcrumb-separator {
  display: inline-block;
  padding: 0 .5407911001em;
}

.product_list_widget {
  list-style: none;
  margin-left: 0;
}

.product_list_widget li {
  padding: 1em 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.product_list_widget li:after,
.product_list_widget li:before {
  content: "";
  display: table;
}

.product_list_widget li:after {
  clear: both;
}

.product_list_widget li img {
  max-width: 2.617924em;
  float: right;
}

.product_list_widget li > a {
  display: block;
}

.product_list_widget a:hover {
  color: #2c2d33;
}

.widget ul.products li.product a {
  text-decoration: none;
}

.widget_products a {
  display: block;
}

.widget_shopping_cart .product_list_widget {
  margin-bottom: 0;
}

.widget_shopping_cart .product_list_widget li {
  padding-left: 2em;
  position: relative;
}

.widget_shopping_cart .product_list_widget li a.remove {
  position: absolute;
  top: 1em;
  left: 0;
  opacity: 1;
}

.widget_shopping_cart p.buttons,
.widget_shopping_cart p.total {
  padding: 1em 0;
  margin: 0;
  text-align: center;
}

.widget_shopping_cart p.total {
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.widget_shopping_cart .buttons .button {
  display: block;
}

.widget_shopping_cart .buttons .button:nth-child(odd) {
  margin-bottom: .6180469716em;
}

.site-header .widget_shopping_cart li,
.site-header .widget_shopping_cart p.buttons,
.site-header .widget_shopping_cart p.total {
  padding-left: 1.41575em;
  padding-right: 1.41575em;
}

.widget_layered_nav li {
  margin-bottom: .6180469716em;
}

.widget.woocommerce li .count {
  font-size: 1em;
  float: right;
}

.widget_price_filter .price_slider {
  margin-bottom: 1em;
}

.widget_price_filter .price_slider_amount {
  text-align: right;
  line-height: 2.4em;
}

.widget_price_filter .price_slider_amount .button {
  float: left;
}

.widget_price_filter .ui-slider {
  position: relative;
  text-align: left;
}

.widget_price_filter .ui-slider .ui-slider-handle {
  position: absolute;
  z-index: 2;
  width: 1em;
  height: 1em;
  border-top-left-radius: 1em;
  border-top-right-radius: 1em;
  border-bottom-left-radius: 1em;
  border-bottom-right-radius: 1em;
  cursor: ew-resize;
  outline: 0;
  background: #2c2d33;
  box-sizing: border-box;
  margin-top: -.25em;
  opacity: 1;
}

.widget_price_filter .ui-slider .ui-slider-handle:last-child {
  margin-left: -1em;
}

.widget_price_filter .ui-slider .ui-slider-handle.ui-state-active,
.widget_price_filter .ui-slider .ui-slider-handle:hover {
  box-shadow: 0 0 0 0.236em rgba(0, 0, 0, 0.1);
}

.widget_price_filter .ui-slider .ui-slider-range {
  position: absolute;
  z-index: 1;
  font-size: .7em;
  display: block;
  border: 0;
  background: #2c2d33;
  border-top-left-radius: 1em;
  border-top-right-radius: 1em;
  border-bottom-left-radius: 1em;
  border-bottom-right-radius: 1em;
}

.widget_price_filter .price_slider_wrapper .ui-widget-content {
  border-top-left-radius: 1em;
  border-top-right-radius: 1em;
  border-bottom-left-radius: 1em;
  border-bottom-right-radius: 1em;
  background: rgba(0, 0, 0, 0.1);
}

.widget_price_filter .ui-slider-horizontal {
  height: .5em;
}

.widget_price_filter .ui-slider-horizontal .ui-slider-range {
  top: 0;
  height: 100%;
}

.widget_price_filter .ui-slider-horizontal .ui-slider-range-min {
  left: -1px;
}

.widget_price_filter .ui-slider-horizontal .ui-slider-range-max {
  right: -1px;
}

table.cart {
  margin-bottom: 3.706325903em;
}

table.cart .product-quantity .minus,
table.cart .product-quantity .plus {
  display: none;
}

table.cart .product-thumbnail img {
  margin: 0 auto;
  max-width: 3.706325903em;
  height: auto;
}

table.cart .product-thumbnail:before {
  display: none;
}

table.cart tr:first-child td.product-remove {
  border-top-width: 0;
}

table.cart td.product-remove {
  padding: 0;
  border-top: 1.618em solid;
  position: relative;
}

table.cart td.product-remove:after,
table.cart td.product-remove:before {
  content: "";
  display: table;
}

table.cart td.product-remove:after {
  clear: both;
}

table.cart td.product-remove a.remove {
  position: absolute;
  font-size: 1.41575em;
  top: -.6180469716em;
  right: -.6180469716em;
}

table.cart td.product-quantity .qty {
  padding: .326em;
  width: 3.706325903em;
}

table.cart td,
table.cart th {
  padding: .875em .875em 0;
}

table.cart td.product-subtotal {
  padding-bottom: .875em;
}

table.cart td.actions {
  border-top: 1em solid;
  background-color: transparent;
  padding: 0;
}

table.cart td.actions label {
  display: none;
}

table.cart td.actions .coupon {
  padding-bottom: 1em;
  margin-bottom: 1em;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

table.cart td.actions input {
  display: block;
  width: 100%;
  margin: .5407911001em 0;
}

table.cart td.actions input[name=update_cart]:focus {
  outline: 0;
}

.wc-proceed-to-checkout {
  margin-bottom: 1.618em;
}

.wc-proceed-to-checkout:after,
.wc-proceed-to-checkout:before {
  content: "";
  display: table;
}

.wc-proceed-to-checkout:after {
  clear: both;
}

.wc-proceed-to-checkout .button {
  display: block;
  text-align: center;
  margin-bottom: 1em;
}

.wc-proceed-to-checkout .button.checkout-button {
  font-size: 1.41575em;
}

.woocommerce-shipping-calculator {
  margin: 0;
}

.woocommerce-shipping-calculator > p:first-child {
  margin: 0;
}

.woocommerce-shipping-calculator .shipping-calculator-form {
  padding-top: 1.41575em;
}

ul#shipping_method {
  list-style: none;
  margin-left: 0;
}

ul#shipping_method li {
  margin-bottom: .2360828548em;
}

ul#shipping_method li:last-child {
  margin-bottom: 0;
}

ul#shipping_method input {
  margin-right: .3342343017em;
}

.woocommerce-checkout ul#shipping_method {
  margin-bottom: 0;
}

.checkout_coupon {
  margin-bottom: 2.617924em;
}

form.checkout {
  position: static !important;
}

form.checkout:after,
form.checkout:before {
  content: "";
  display: table;
}

form.checkout:after {
  clear: both;
}

form.checkout > .blockUI {
  position: fixed !important;
}

#payment:after,
#payment:before {
  content: "";
  display: table;
}

#payment:after {
  clear: both;
}

#payment .create-account {
  clear: both;
}

#payment .payment_methods {
  margin: 0 !important;
  padding: 0 !important;
}

#payment .payment_methods > .wc_payment_method > label {
  display: block;
  padding: 1.41575em;
  cursor: pointer;
}

#payment .payment_methods li {
  list-style: none !important;
  margin-left: 0;
  position: relative;
}

#payment .payment_methods li img {
  float: right;
  border: 0;
  padding: 0;
  max-height: 1.618em;
}

#payment .payment_methods li:last-child:not(.woocommerce-notice) {
  padding-bottom: 0;
  border-bottom: 0;
}

#payment .payment_methods li p {
  margin: 0;
}

#payment .payment_methods li .payment_box {
  padding: 1.41575em;
}

#payment .payment_methods li .payment_box fieldset {
  border: 0;
  margin: 0;
  padding: 1em 0 0 0;
  font-size: .875em;
}

#payment .payment_methods li .payment_box fieldset label {
  width: auto;
  margin-left: 0;
}

#payment .payment_methods li .payment_box fieldset .form-row {
  margin-bottom: 1em;
}

#payment .payment_methods li .payment_box fieldset .form-row input[type=checkbox] {
  margin-right: 5px;
}

#payment .payment_methods li .payment_box fieldset #cvv {
  width: 22% !important;
}

#payment .payment_methods li .payment_box fieldset .help {
  width: 75%;
  float: right;
}

#payment .payment_methods li .payment_box .woocommerce-SavedPaymentMethods {
  padding-top: 1em;
  margin: 0;
}

#payment .payment_methods li .payment_box .woocommerce-SavedPaymentMethods li {
  margin-top: .6180469716em;
}

#payment .payment_methods li .payment_box .woocommerce-SavedPaymentMethods li input[type=radio] {
  margin-right: .236em;
}

#payment .payment_methods li .payment_box ul.wc-saved-payment-methods {
  margin-left: 0;
}

#payment .payment_methods li .payment_box .wc-credit-card-form-card-cvc,
#payment .payment_methods li .payment_box .wc-credit-card-form-card-expiry,
#payment .payment_methods li .payment_box .wc-credit-card-form-card-number {
  font-size: 1.41575em;
}

#payment .payment_methods li .payment_box .wc-credit-card-form-card-number {
  background-repeat: no-repeat;
  background-position: right .6180469716em center;
  background-size: 31px 20px;
  background-image: url(../../../assets/images/credit-cards/unknown.svg);
}

#payment .payment_methods li .payment_box .wc-credit-card-form-card-number.visa {
  background-image: url(../../../assets/images/credit-cards/visa.svg);
}

#payment .payment_methods li .payment_box .wc-credit-card-form-card-number.mastercard {
  background-image: url(../../../assets/images/credit-cards/mastercard.svg);
}

#payment .payment_methods li .payment_box .wc-credit-card-form-card-number.laser {
  background-image: url(../../../assets/images/credit-cards/laser.svg);
}

#payment .payment_methods li .payment_box .wc-credit-card-form-card-number.dinersclub {
  background-image: url(../../../assets/images/credit-cards/diners.svg);
}

#payment .payment_methods li .payment_box .wc-credit-card-form-card-number.maestro {
  background-image: url(../../../assets/images/credit-cards/maestro.svg);
}

#payment .payment_methods li .payment_box .wc-credit-card-form-card-number.jcb {
  background-image: url(../../../assets/images/credit-cards/jcb.svg);
}

#payment .payment_methods li .payment_box .wc-credit-card-form-card-number.amex {
  background-image: url(../../../assets/images/credit-cards/amex.svg);
}

#payment .payment_methods li .payment_box .wc-credit-card-form-card-number.discover {
  background-image: url(../../../assets/images/credit-cards/discover.svg);
}

#payment .terms {
  width: 100%;
  clear: both;
  float: none;
  margin-bottom: 0;
  margin-bottom: 1em;
}

#payment .terms label {
  display: block;
  cursor: pointer;
}

#payment .woocommerce-terms-and-conditions {
  padding: 1em;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.2);
  margin-bottom: 16px;
  background-color: rgba(0, 0, 0, 0.05);
}

#payment .place-order {
  padding: 1.41575em;
  margin-bottom: 0;
  float: none;
  width: 100%;
  margin-top: 2.617924em;
}

#payment .place-order .button {
  font-size: 1.41575em;
  width: 100%;
  white-space: pre-wrap;
}

table.woocommerce-checkout-review-order-table .product-name {
  width: 300px;
  word-wrap: break-word;
}

.woocommerce-checkout .payment-fixed {
  position: fixed;
  top: 0;
  z-index: 9;
  box-shadow: 0 6px 2em rgba(0, 0, 0, 0.2);
}

.admin-bar .woocommerce-checkout .payment-fixed {
  top: 32px;
}

.admin-bar .woocommerce-checkout #wc_checkout_add_ons label + br {
  display: none;
}

.woocommerce-password-strength {
  text-align: left;
  font-weight: 600;
  padding: .5407911001em 0;
  text-align: right;
  font-size: .875em;
}

.woocommerce-password-strength.strong {
  color: #0f834d;
}

.woocommerce-password-strength.short {
  color: #e2401c;
}

.woocommerce-password-strength.bad {
  color: #e2401c;
}

.woocommerce-password-strength.good {
  color: #3d9cd2;
}

label.inline {
  display: inline !important;
  margin-left: 1em;
}

.woocommerce-MyAccount-navigation ul {
  margin-left: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.05);
}

.woocommerce-MyAccount-navigation ul li {
  list-style: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  position: relative;
}

.woocommerce-MyAccount-navigation ul li a {
  padding: .875em 0;
  display: block;
}

ul.order_details {
  list-style: none;
  position: relative;
  margin: 3.706325903em 0;
}

ul.order_details:after,
ul.order_details:before {
  content: "";
  display: table;
}

ul.order_details:after {
  clear: both;
}

ul.order_details:after,
ul.order_details:before {
  content: "";
  display: block;
  position: absolute;
  top: -16px;
  left: 0;
  width: 100%;
  height: 16px;
  background-size: 0 100%, 16px 16px, 16px 16px;
}

ul.order_details:after {
  top: auto;
  bottom: -16px;
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}

ul.order_details li {
  padding: 1em 1.618em;
  font-size: .8em;
  text-transform: uppercase;
}

ul.order_details li:first-child {
  padding-top: 1.618em;
}

ul.order_details li:last-child {
  border: 0;
  padding-bottom: 1.618em;
}

ul.order_details li strong {
  display: block;
  font-size: 1.41575em;
  text-transform: none;
}

.my_account_orders .button,
.woocommerce-MyAccount-downloads .button {
  padding: .6180469716em .875em;
  font-size: .875em;
  margin-right: .236em;
}

.woocommerce-MyAccount-content h2 {
  font-size: 2em;
  font-weight: 600;
}

.woocommerce-MyAccount-content #payment .payment_methods {
  margin-bottom: 1.618em !important;
}

.woocommerce-MyAccount-content #payment .payment_methods li .payment_box {
  background-color: rgba(0, 0, 0, 0.05);
}

.woocommerce-form__label-for-checkbox {
  cursor: pointer;
  display: block;
}

.woocommerce-form__label-for-checkbox .woocommerce-form__input-checkbox {
  margin-right: .3342343017em;
}

.form-row label {
  display: block;
}

.form-row input,
.form-row select,
.form-row textarea {
  width: 100%;
}

.form-row input[type=checkbox],
.form-row input[type=radio] {
  width: auto;
}

.form-row .button {
  width: auto;
}

.form-row.create-account label {
  display: inline-block;
}

.form-row.woocommerce-validated input.input-text {
  box-shadow: inset 2px 0 0 #0f834d;
}

.form-row.woocommerce-invalid input.input-text {
  box-shadow: inset 2px 0 0 #e2401c;
}

.form-row-last {
  margin-right: 0 !important;
}

.form-row-wide {
  clear: both;
  width: 100%;
}

.required {
  border-bottom: 0 !important;
  color: red;
}

.demo_store {
  position: fixed;
  left: 0;
  bottom: 69px;
  right: 0;
  margin: 0;
  padding: 1em 1.41575em;
  background-color: #3d9cd2;
  color: #fff;
  z-index: 9999;
}

.demo_store a {
  color: #fff;
  font-weight: 400;
  text-decoration: underline;
  display: block;
}

.demo_store a:hover {
  text-decoration: none;
}

.star-rating {
  overflow: hidden;
  position: relative;
  height: 1.618em;
  line-height: 1.618;
  font-size: 1em;
  width: 5.3em;
  font-family: star;
  font-weight: 400;
}

.star-rating:before {
  content: "\53\53\53\53\53";
  opacity: .25;
  float: left;
  top: 0;
  left: 0;
  position: absolute;
}

.star-rating span {
  overflow: hidden;
  float: left;
  top: 0;
  left: 0;
  position: absolute;
  padding-top: 1.5em;
}

.star-rating span:before {
  content: "\53\53\53\53\53";
  top: 0;
  position: absolute;
  left: 0;
  color: #2c2d33;
}

p.stars {
  display: inline-block;
  margin: 0;
}

p.stars a {
  position: relative;
  height: 1em;
  width: 1em;
  text-indent: -999em;
  display: inline-block;
  text-decoration: none;
  margin-right: 1px;
  font-weight: 400;
}

p.stars a:before {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 1em;
  height: 1em;
  line-height: 1;
  font-family: star;
  content: "\53";
  color: #43454b;
  text-indent: 0;
  opacity: .25;
}

p.stars a:hover ~ a:before {
  content: "\53";
  color: #43454b;
  opacity: .25;
}

p.stars:hover a:before {
  content: "\53";
  color: #96588a;
  opacity: 1;
}

p.stars.selected a.active:before {
  content: "\53";
  color: #96588a;
  opacity: 1;
}

p.stars.selected a.active ~ a:before {
  content: "\53";
  color: #43454b;
  opacity: .25;
}

p.stars.selected a:not(.active):before {
  content: "\53";
  color: #96588a;
  opacity: 1;
}

.onsale {
  border: 1px solid;
  border-color: #43454b;
  color: #43454b;
  padding: .202em .6180469716em;
  font-size: .875em;
  text-transform: uppercase;
  font-weight: 600;
  display: inline-block;
  margin-bottom: 1em;
  border-radius: 3px;
}

.quantity .qty {
  width: 4.235801032em;
  text-align: center;
}

.storefront-sorting {
  font-size: .875em;
  margin-bottom: 2.617924em;
}

.woocommerce-tabs {
  padding: 1em 0;
}

.woocommerce-tabs ul.tabs {
  list-style: none;
  margin-left: 0;
  text-align: left;
  border-top: 1px solid rgba(0, 0, 0, 0.05);
}

.woocommerce-tabs ul.tabs li {
  display: block;
  margin: 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  position: relative;
}

.woocommerce-tabs ul.tabs li a {
  padding: 1em 0;
  display: block;
}

.woocommerce-tabs .panel h2:first-of-type {
  font-size: 1.618em;
  margin-bottom: 1em;
}

.related > h2:first-child,
.upsells > h2:first-child {
  font-size: 1.618em;
  margin-bottom: 1em;
}

.woocommerce-error,
.woocommerce-info,
.woocommerce-message,
.woocommerce-noreviews,
p.no-comments {
  padding: 1em 1.618em;
  margin-bottom: 2.617924em;
  background-color: #0f834d;
  margin-left: 0;
  border-radius: 2px;
  color: #fff;
  clear: both;
  border-left: 0.61805em solid rgba(0, 0, 0, 0.15);
}

.woocommerce-error:after,
.woocommerce-error:before,
.woocommerce-info:after,
.woocommerce-info:before,
.woocommerce-message:after,
.woocommerce-message:before,
.woocommerce-noreviews:after,
.woocommerce-noreviews:before,
p.no-comments:after,
p.no-comments:before {
  content: "";
  display: table;
}

.woocommerce-error:after,
.woocommerce-info:after,
.woocommerce-message:after,
.woocommerce-noreviews:after,
p.no-comments:after {
  clear: both;
}

.woocommerce-error a,
.woocommerce-info a,
.woocommerce-message a,
.woocommerce-noreviews a,
p.no-comments a {
  color: #fff;
}

.woocommerce-error a:hover,
.woocommerce-info a:hover,
.woocommerce-message a:hover,
.woocommerce-noreviews a:hover,
p.no-comments a:hover {
  color: #fff;
  opacity: .7;
}

.woocommerce-error a.button:hover,
.woocommerce-info a.button:hover,
.woocommerce-message a.button:hover,
.woocommerce-noreviews a.button:hover,
p.no-comments a.button:hover {
  opacity: 1;
}

.woocommerce-error .button,
.woocommerce-info .button,
.woocommerce-message .button,
.woocommerce-noreviews .button,
p.no-comments .button {
  float: right;
  padding: 0;
  background: 0 0;
  color: #fff;
  box-shadow: none;
  line-height: 1.618;
  padding-left: 1em;
  border-width: 0;
  border-left-width: 1px;
  border-left-style: solid;
  border-left-color: rgba(255, 255, 255, 0.25) !important;
  border-radius: 0;
}

.woocommerce-error .button:hover,
.woocommerce-info .button:hover,
.woocommerce-message .button:hover,
.woocommerce-noreviews .button:hover,
p.no-comments .button:hover {
  background: 0 0;
  color: #fff;
  opacity: .7;
}

.woocommerce-error pre,
.woocommerce-info pre,
.woocommerce-message pre,
.woocommerce-noreviews pre,
p.no-comments pre {
  background-color: rgba(0, 0, 0, 0.1);
}

.site-content > .col-full > .woocommerce > .woocommerce-error:first-child,
.site-content > .col-full > .woocommerce > .woocommerce-info:first-child,
.site-content > .col-full > .woocommerce > .woocommerce-message:first-child {
  margin-top: 2.617924em;
}

.woocommerce-error {
  list-style: none;
}

.woocommerce-info,
.woocommerce-noreviews,
p.no-comments {
  background-color: #3d9cd2;
}

.woocommerce-error {
  background-color: #e2401c;
}

dl.variation {
  font-size: .875em;
}

dl.variation dd {
  margin: 0 0 1em;
}

.wc-item-meta {
  font-size: .875em;
  margin-left: 0;
  list-style: none;
}

.wc-item-meta li:after,
.wc-item-meta li:before {
  content: "";
  display: table;
}

.wc-item-meta li:after {
  clear: both;
}

.wc-item-meta li p,
.wc-item-meta li strong {
  display: inline-block;
}

.woocommerce-MyAccount-content .woocommerce-Pagination {
  text-align: center;
}

.storefront-product-section {
  margin-bottom: 1.618em;
}

.storefront-product-section .section-title {
  text-align: center;
  margin-bottom: 1em;
}

.storefront-product-section:last-child {
  margin-bottom: 0;
}

@media (min-width: 768px) {
  .demo_store {
    bottom: 0;
  }
  .demo_store a {
    background: rgba(0, 0, 0, 0.1);
    float: right;
    display: inline-block;
    margin: -1em -1.41575em -1em 0;
    padding: 1em 1.41575em;
  }
  .storefront-handheld-footer-bar {
    display: none;
  }
  .woocommerce-breadcrumb {
    padding: 1.41575em 99em 1.41575em;
    margin-left: -99em;
    margin-right: -99em;
    margin-bottom: 4.235801032em;
  }
  table.shop_table_responsive thead {
    display: table-header-group;
  }
  table.shop_table_responsive tbody th {
    display: table-cell;
  }
  table.shop_table_responsive tr td {
    display: table-cell;
  }
  table.shop_table_responsive tr td:before {
    display: none;
  }
  .site-header-cart {
    position: relative;
  }
  .site-header-cart .cart-contents {
    padding: 1.618em 0;
    display: block;
    position: relative;
    background-color: transparent;
    height: auto;
    width: auto;
    text-indent: 0;
  }
  .site-header-cart .cart-contents .amount {
    margin-right: .327em;
  }
  .site-header-cart .cart-contents .count {
    font-weight: 300;
    opacity: .5;
    font-size: .875em;
  }
  .site-header-cart .cart-contents span {
    display: inline;
  }
  .site-header-cart .widget_shopping_cart {
    position: absolute;
    top: 100%;
    width: 100%;
    z-index: 999999;
    font-size: .875em;
    left: -999em;
    display: block;
  }
  .site-header-cart .widget_shopping_cart.sub-menu--is-touch-device {
    display: none;
    left: 0;
  }
  .site-header-cart .widget_shopping_cart h2.widgettitle {
    display: none;
  }
  .site-header-cart .widget_shopping_cart .product_list_widget li a.remove {
    position: relative;
    float: left;
    top: auto;
  }
  .site-header-cart .widget_shopping_cart .product_list_widget li a.remove:before {
    text-align: left;
  }
  .site-header-cart.focus .widget_shopping_cart,
  .site-header-cart:hover .widget_shopping_cart {
    left: 0;
    display: block;
  }
  .site-search {
    display: block;
  }
  .site-search form {
    margin: 0;
  }
  ul.products li.product {
    clear: none;
    width: 100%;
    float: left;
    font-size: .875em;
  }
  ul.products li.product.first {
    clear: both !important;
  }
  ul.products li.product.last {
    margin-right: 0 !important;
  }
  .page-template-template-fullwidth-php .site-main .columns-1 ul.products li.product,
  .page-template-template-homepage-php .site-main .columns-1 ul.products li.product,
  .storefront-full-width-content .site-main .columns-1 ul.products li.product {
    width: 100%;
    margin-right: 0;
    float: none;
  }
  .page-template-template-fullwidth-php .site-main .columns-2 ul.products li.product,
  .page-template-template-homepage-php .site-main .columns-2 ul.products li.product,
  .storefront-full-width-content .site-main .columns-2 ul.products li.product {
    width: 47.8260869565%;
    float: left;
    margin-right: 4.347826087%;
  }
  .page-template-template-fullwidth-php .site-main .columns-3 ul.products li.product,
  .page-template-template-homepage-php .site-main .columns-3 ul.products li.product,
  .storefront-full-width-content .site-main .columns-3 ul.products li.product {
    width: 30.4347826087%;
    float: left;
    margin-right: 4.347826087%;
  }
  .page-template-template-fullwidth-php .site-main .columns-4 ul.products li.product,
  .page-template-template-homepage-php .site-main .columns-4 ul.products li.product,
  .storefront-full-width-content .site-main .columns-4 ul.products li.product {
    width: 21.7391304348%;
    float: left;
    margin-right: 4.347826087%;
  }
  .page-template-template-fullwidth-php .site-main .columns-5 ul.products li.product,
  .page-template-template-homepage-php .site-main .columns-5 ul.products li.product,
  .storefront-full-width-content .site-main .columns-5 ul.products li.product {
    width: 16.9%;
    margin-right: 3.8%;
  }
  .page-template-template-fullwidth-php .site-main .columns-6 ul.products li.product,
  .page-template-template-homepage-php .site-main .columns-6 ul.products li.product,
  .storefront-full-width-content .site-main .columns-6 ul.products li.product {
    width: 13.0434782609%;
    float: left;
    margin-right: 4.347826087%;
  }
  .site-main .columns-1 ul.products li.product {
    width: 100%;
    margin-right: 0;
    float: none;
  }
  .site-main .columns-2 ul.products li.product {
    width: 48%;
    margin-right: 3.8%;
  }
  .site-main .columns-3 ul.products li.product {
    width: 29.4117647059%;
    float: left;
    margin-right: 5.8823529412%;
  }
  .site-main .columns-4 ul.products li.product {
    width: 22.05%;
    margin-right: 3.8%;
  }
  .site-main .columns-5 ul.products li.product {
    width: 16.9%;
    margin-right: 3.8%;
  }
  .site-main .columns-6 ul.products li.product {
    width: 11.7647058824%;
    float: left;
    margin-right: 5.8823529412%;
  }
  .site-main ul.products li.product {
    width: 29.4117647059%;
    float: left;
    margin-right: 5.8823529412%;
  }
  .single-product div.product .images {
    width: 41.1764705882%;
    float: left;
    margin-right: 5.8823529412%;
    margin-bottom: 3.706325903em;
  }
  .single-product div.product .images .thumbnails:after,
  .single-product div.product .images .thumbnails:before {
    content: "";
    display: table;
  }
  .single-product div.product .images .thumbnails:after {
    clear: both;
  }
  .single-product div.product .images .thumbnails a.zoom {
    width: 14.2857142857%;
    float: left;
    margin-right: 14.2857142857%;
  }
  .single-product div.product .images .thumbnails a.zoom.last {
    float: right;
    margin-right: 0;
  }
  .single-product div.product .woocommerce-product-gallery {
    width: 41.1764705882%;
    float: left;
    margin-right: 5.8823529412%;
    margin-bottom: 3.706325903em;
  }
  .single-product div.product .summary {
    width: 52.9411764706%;
    float: right;
    margin-right: 0;
  }
  .single-product div.product .woocommerce-tabs {
    clear: both;
  }
  #reviews .comment-form-author,
  #reviews .comment-form-email {
    width: 47%;
  }
  #reviews .comment-form-email {
    margin-right: 0;
  }
  .storefront-full-width-content.single-product div.product .images {
    width: 39.1304347826%;
    float: left;
    margin-right: 4.347826087%;
    margin-bottom: 3.706325903em;
  }
  .storefront-full-width-content.single-product div.product .images .thumbnails a.zoom {
    width: 11.1111111111%;
    float: left;
    margin-right: 11.1111111111%;
  }
  .storefront-full-width-content.single-product div.product .images .thumbnails a.zoom.last {
    margin-right: 0;
  }
  .storefront-full-width-content.single-product div.product .woocommerce-product-gallery {
    width: 39.1304347826%;
    float: left;
    margin-right: 4.347826087%;
    margin-bottom: 3.706325903em;
  }
  .storefront-full-width-content.single-product div.product .woocommerce-product-gallery.woocommerce-product-gallery--columns-2 .flex-control-thumbs li {
    width: 44.4444444444%;
    float: left;
    margin-right: 11.1111111111%;
  }
  .storefront-full-width-content.single-product div.product .woocommerce-product-gallery.woocommerce-product-gallery--columns-2 .flex-control-thumbs li:nth-child(2n) {
    margin-right: 0;
  }
  .storefront-full-width-content.single-product div.product .woocommerce-product-gallery.woocommerce-product-gallery--columns-3 .flex-control-thumbs li {
    width: 25.9259259333%;
    float: left;
    margin-right: 11.1111111111%;
  }
  .storefront-full-width-content.single-product div.product .woocommerce-product-gallery.woocommerce-product-gallery--columns-3 .flex-control-thumbs li:nth-child(3n) {
    margin-right: 0;
  }
  .storefront-full-width-content.single-product div.product .woocommerce-product-gallery.woocommerce-product-gallery--columns-4 .flex-control-thumbs li {
    width: 16.6666666667%;
    float: left;
    margin-right: 11.1111111111%;
  }
  .storefront-full-width-content.single-product div.product .woocommerce-product-gallery.woocommerce-product-gallery--columns-4 .flex-control-thumbs li:nth-child(4n) {
    margin-right: 0;
  }
  .storefront-full-width-content.single-product div.product .woocommerce-product-gallery.woocommerce-product-gallery--columns-5 .flex-control-thumbs li {
    width: 11.1111111111%;
    float: left;
    margin-right: 11.1111111111%;
  }
  .storefront-full-width-content.single-product div.product .woocommerce-product-gallery.woocommerce-product-gallery--columns-5 .flex-control-thumbs li:nth-child(5n) {
    margin-right: 0;
  }
  .storefront-full-width-content.single-product div.product .summary {
    width: 56.5217391304%;
    float: right;
    margin-right: 0;
    margin-bottom: 3.706325903em;
  }
  .storefront-full-width-content.single-product div.product .woocommerce-tabs {
    clear: both;
  }
  .storefront-full-width-content.single-product #reviews .commentlist li .avatar {
    width: 6.6666666667%;
    float: left;
    margin-right: 6.6666666667%;
  }
  .storefront-full-width-content.single-product #reviews .commentlist li .comment_container .comment-text {
    width: 86.6666666667%;
    float: right;
    margin-right: 0;
  }
  .storefront-full-width-content.single-product #reviews .commentlist ul.children {
    width: 86.6666666667%;
    float: right;
    margin-right: 0;
  }
  .storefront-full-width-content.single-product #reviews .commentlist ul.children .avatar {
    width: 7.6923076923%;
    float: left;
    margin-right: 7.6923076923%;
  }
  .storefront-full-width-content.single-product #reviews .commentlist ul.children .comment-text {
    width: 84.6153846154%;
    float: right;
    margin-right: 0;
  }
  .storefront-full-width-content.single-product .related ul.products li.product,
  .storefront-full-width-content.single-product .upsells ul.products li.product {
    width: 30.4347826087%;
    float: left;
    margin-right: 4.347826087%;
  }
  .storefront-full-width-content .woocommerce-products-header,
  .storefront-full-width-content.woocommerce-account .entry-header,
  .storefront-full-width-content.woocommerce-cart .entry-header,
  .storefront-full-width-content.woocommerce-checkout .entry-header {
    text-align: center;
    padding: 0 0 5.9968353111em;
  }
  .storefront-full-width-content .woocommerce-products-header h1:last-child,
  .storefront-full-width-content.woocommerce-account .entry-header h1:last-child,
  .storefront-full-width-content.woocommerce-cart .entry-header h1:last-child,
  .storefront-full-width-content.woocommerce-checkout .entry-header h1:last-child {
    margin-bottom: 0;
  }
  .storefront-full-width-content .related.products > h2:first-child,
  .storefront-full-width-content .up-sells > h2:first-child {
    text-align: center;
  }
  .woocommerce .page-description {
    margin-bottom: 1.618em;
  }
  .storefront-sorting {
    margin-bottom: 2.617924em;
  }
  .storefront-sorting:after,
  .storefront-sorting:before {
    content: "";
    display: table;
  }
  .storefront-sorting:after {
    clear: both;
  }
  .storefront-sorting select {
    width: auto;
  }
  .woocommerce-result-count {
    float: left;
    position: relative;
    margin-bottom: 0;
    padding: .327em 0;
  }
  .woocommerce-ordering {
    float: left;
    margin-right: 1em;
    margin-bottom: 0;
    padding: .327em 0;
  }
  .woocommerce-pagination {
    float: right;
    border: 0;
    clear: none;
    padding: 0;
    margin-bottom: 0;
  }
  .woocommerce-cart .hentry,
  .woocommerce-checkout .hentry {
    border-bottom: 0;
    padding-bottom: 0;
  }
  .page-template-template-fullwidth-php table.cart .product-thumbnail {
    display: table-cell;
  }
  .page-template-template-fullwidth-php table.cart thead {
    font-size: 1em;
    text-indent: 0;
  }
  table.cart td,
  table.cart th {
    padding: 1.618em;
  }
  table.cart .qty {
    padding: .875em;
    max-width: 3.632em;
  }
  table.cart td.product-remove {
    display: table-cell;
    padding: 1.618em;
    border: 0;
  }
  table.cart td.product-remove a.remove {
    float: none;
    position: relative;
    top: auto;
    right: auto;
  }
  table.cart .product-quantity .minus,
  table.cart .product-quantity .plus {
    display: inline-block;
  }
  table.cart .quantity .qty {
    padding: .6180469716em;
  }
  table.cart td.actions {
    text-align: right;
  }
  table.cart td.actions label {
    display: none;
  }
  table.cart td.actions .coupon {
    padding-bottom: 0;
    margin-bottom: 0;
    border-bottom: none;
    display: inline-block;
    float: left;
  }
  table.cart td.actions input {
    display: inline-block;
    width: auto;
    margin: 0;
  }
  .cart-collaterals:after,
  .cart-collaterals:before {
    content: "";
    display: table;
  }
  .cart-collaterals:after {
    clear: both;
  }
  .cart-collaterals h2 {
    font-size: 1.618em;
  }
  .cart-collaterals .cross-sells {
    width: 41.1764705882%;
    float: left;
    margin-right: 5.8823529412%;
  }
  .cart-collaterals .cross-sells ul.products li.product {
    width: 42.8571428571%;
    float: left;
    margin-right: 14.2857142857%;
  }
  .cart-collaterals .cross-sells ul.products li.product:nth-child(2n) {
    margin-right: 0 !important;
  }
  .cart-collaterals .cart_totals,
  .cart-collaterals .shipping_calculator {
    width: 52.9411764706%;
    float: right;
    margin-right: 0;
    margin: 0;
    clear: right;
  }
  .cart-collaterals .cart_totals small.includes_tax {
    display: block;
  }
  .cart-collaterals .shipping_calculator {
    clear: right;
  }
  .page-template-template-fullwidth-php .cart-collaterals .cross-sells {
    width: 47.8260869565%;
    float: left;
    margin-right: 4.347826087%;
  }
  .page-template-template-fullwidth-php .cart-collaterals .cross-sells ul.products li.product {
    width: 45.4545454545%;
    float: left;
    margin-right: 9.0909090909%;
  }
  .page-template-template-fullwidth-php .cart-collaterals .cart_totals,
  .page-template-template-fullwidth-php .cart-collaterals .shipping_calculator {
    width: 47.8260869565%;
    float: right;
    margin-right: 0;
  }
  #ship-to-different-address {
    position: relative;
  }
  #ship-to-different-address label {
    font-weight: 300;
  }
  #ship-to-different-address .woocommerce-form__input-checkbox {
    position: absolute;
    top: 50%;
    right: 0;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    margin-right: 0;
  }
  .col2-set {
    width: 52.9411764706%;
    float: left;
    margin-right: 5.8823529412%;
  }
  .col2-set .form-row-first {
    width: 33.3333333333%;
    float: left;
    margin-right: 11.1111111111%;
  }
  .col2-set .form-row-last {
    width: 55.5555555556%;
    float: right;
    margin-right: 0;
  }
  .col2-set .col-1,
  .col2-set .col-2 {
    margin-bottom: 1.618em;
  }
  .col2-set + #customer_details {
    display: none;
  }
  .col2-set .woocommerce-billing-fields:after,
  .col2-set .woocommerce-billing-fields:before {
    content: "";
    display: table;
  }
  .col2-set .woocommerce-billing-fields:after {
    clear: both;
  }
  .col2-set.addresses {
    width: 100%;
    float: left;
    margin-left: 0;
    margin-right: 0;
  }
  .col2-set.addresses .col-1 {
    width: 47.0588235294%;
    float: left;
    margin-right: 5.8823529412%;
  }
  .col2-set.addresses .col-2 {
    width: 47.0588235294%;
    float: right;
    margin-right: 0;
  }
  #customer_details + #wc_checkout_add_ons {
    width: 41.1764705882%;
    float: right;
    margin-right: 0;
  }
  #wc_checkout_add_ons input[type=radio] {
    float: left;
    margin-right: .5407911001em;
    clear: left;
    margin-top: .35em;
  }
  #order_review,
  #order_review_heading {
    width: 41.1764705882%;
    float: right;
    margin-right: 0;
    clear: right;
  }
  #order_review {
    -webkit-transition: box-shadow ease .4s;
    transition: box-shadow ease .4s;
  }
  #order_review_heading {
    margin-bottom: 0;
    padding-bottom: .6180469716em;
  }
  .page-template-template-fullwidth-php.woocommerce-order-pay #order_review,
  .woocommerce-order-pay #order_review {
    width: 100%;
    float: none;
  }
  .page-template-template-fullwidth-php.woocommerce-order-pay #order_review #payment h3,
  .woocommerce-order-pay #order_review #payment h3 {
    padding-left: 1em;
    padding-top: 1em;
  }
  .page-template-template-fullwidth-php.woocommerce-order-pay #order_review #payment .form-row,
  .woocommerce-order-pay #order_review #payment .form-row {
    padding: 1em;
  }
  .page-template-template-fullwidth-php.woocommerce-order-pay #order_review .payment_methods + .form-row,
  .woocommerce-order-pay #order_review .payment_methods + .form-row {
    padding: 1em;
  }
  .page-template-template-fullwidth-php.woocommerce-order-pay #order_review .button#place_order,
  .woocommerce-order-pay #order_review .button#place_order {
    display: block;
    width: 100%;
    font-size: 1.41575em;
  }
  .page-template-template-fullwidth-php .col2-set {
    width: 47.8260869565%;
    float: left;
    margin-right: 4.347826087%;
  }
  .page-template-template-fullwidth-php .col2-set .form-row-first {
    width: 45.4545454545%;
    float: left;
    margin-right: 9.0909090909%;
  }
  .page-template-template-fullwidth-php .col2-set .form-row-last {
    width: 45.4545454545%;
    float: right;
    margin-right: 0;
  }
  .page-template-template-fullwidth-php .col2-set.addresses .col-1 {
    width: 47.8260869565%;
    float: left;
    margin-right: 4.347826087%;
  }
  .page-template-template-fullwidth-php .col2-set.addresses .col-2 {
    width: 47.8260869565%;
    float: right;
    margin-right: 0;
  }
  .page-template-template-fullwidth-php #order_review,
  .page-template-template-fullwidth-php #order_review_heading {
    width: 47.8260869565%;
    float: right;
    margin-right: 0;
  }
  .page-template-template-fullwidth-php #customer_details + #wc_checkout_add_ons {
    width: 47.8260869565%;
    float: right;
    margin-right: 0;
  }
  #order_review .shop_table {
    margin-bottom: 2.617924em;
  }
  table + .addresses {
    margin-top: 1.618em;
  }
  .col2-set#customer_login,
  .col2-set.addresses {
    width: 100%;
    float: left;
    margin-left: 0;
    margin-right: 0;
  }
  .col2-set#customer_login .col-1,
  .col2-set.addresses .col-1 {
    width: 41.1764705882%;
    float: left;
    margin-right: 5.8823529412%;
  }
  .col2-set#customer_login .col-2,
  .col2-set.addresses .col-2 {
    width: 52.9411764706%;
    float: right;
    margin-right: 0;
  }
  .woocommerce-MyAccount-navigation {
    width: 17.6470588235%;
    float: left;
    margin-right: 5.8823529412%;
  }
  .woocommerce-MyAccount-content {
    width: 76.4705882353%;
    float: right;
    margin-right: 0;
  }
  .woocommerce-MyAccount-content .form-row-first {
    width: 38.4615384615%;
    float: left;
    margin-right: 7.6923076923%;
  }
  .woocommerce-MyAccount-content .form-row-last {
    width: 53.8461538462%;
    float: right;
    margin-right: 0;
  }
  .left-sidebar:not(.page-template-template-fullwidth-php) .woocommerce-MyAccount-navigation {
    width: 17.6470588235%;
    float: right;
    margin-right: 0;
  }
  .left-sidebar:not(.page-template-template-fullwidth-php) .woocommerce-MyAccount-content {
    width: 76.4705882353%;
    float: left;
    margin-right: 5.8823529412%;
  }
  table.my_account_orders {
    font-size: .875em;
  }
  .page-template-template-fullwidth-php .col2-set#customer_login .col-1,
  .page-template-template-fullwidth-php .col2-set.addresses .col-1 {
    width: 47.8260869565%;
    float: left;
    margin-right: 4.347826087%;
  }
  .page-template-template-fullwidth-php .col2-set#customer_login .col-2,
  .page-template-template-fullwidth-php .col2-set.addresses .col-2 {
    width: 47.8260869565%;
    float: right;
    margin-right: 0;
  }
  .page-template-template-fullwidth-php .woocommerce-MyAccount-navigation {
    width: 21.7391304348%;
    float: left;
    margin-right: 4.347826087%;
  }
  .page-template-template-fullwidth-php .woocommerce-MyAccount-content {
    width: 73.9130434783%;
    float: right;
    margin-right: 0;
  }
  .page-template-template-fullwidth-php .woocommerce-MyAccount-content .form-row-first {
    width: 46.6666666667%;
    float: left;
    margin-right: 6.6666666667%;
  }
  .page-template-template-fullwidth-php .woocommerce-MyAccount-content .form-row-last {
    width: 46.6666666667%;
    float: right;
    margin-right: 0;
  }
  .page-template-template-fullwidth-php .woocommerce-MyAccount-content .col2-set {
    width: 100%;
  }
  .page-template-template-fullwidth-php .woocommerce-MyAccount-content .col2-set .col-1 {
    width: 46.6666666667%;
    float: left;
    margin-right: 6.6666666667%;
  }
  .page-template-template-fullwidth-php .woocommerce-MyAccount-content .col2-set .col-2 {
    width: 46.6666666667%;
    float: right;
    margin-right: 0;
  }
  .addresses header.title:after,
  .addresses header.title:before {
    content: "";
    display: table;
  }
  .addresses header.title:after {
    clear: both;
  }
  .addresses header.title a {
    float: right;
  }
  .addresses header.title h3 {
    float: left;
  }
  .form-row-first {
    width: 47.0588235294%;
    float: left;
    margin-right: 5.8823529412%;
    clear: both;
  }
  .form-row-last {
    width: 47.0588235294%;
    float: right;
    margin-right: 0;
  }
  .page-template-template-fullwidth-php .form-row-first {
    width: 47.8260869565%;
    float: left;
    margin-right: 4.347826087%;
  }
  .page-template-template-fullwidth-php .form-row-last {
    width: 47.8260869565%;
    float: right;
    margin-right: 0;
  }
  .storefront-full-width-content .woocommerce-tabs ul.tabs {
    width: 30.4347826087%;
    float: left;
    margin-right: 4.347826087%;
  }
  .storefront-full-width-content .woocommerce-tabs .panel {
    width: 65.2173913043%;
    float: right;
    margin-right: 0;
    margin-bottom: 3.706325903em;
  }
  .woocommerce-tabs {
    padding-top: 2.617924em;
    padding-bottom: 2.617924em;
  }
  .woocommerce-tabs:after,
  .woocommerce-tabs:before {
    content: "";
    display: table;
  }
  .woocommerce-tabs:after {
    clear: both;
  }
  .woocommerce-tabs ul.tabs {
    width: 29.4117647059%;
    float: left;
    margin-right: 5.8823529412%;
  }
  .woocommerce-tabs ul.tabs li.active:after {
    right: 1em;
  }
  .woocommerce-tabs .panel {
    width: 64.7058823529%;
    float: right;
    margin-right: 0;
    margin-top: 0;
  }
}

@media (min-width: 568px) and (max-width: 66.49894em) {
  .woocommerce-breadcrumb {
    margin-left: -2.618rem;
    margin-right: -2.618rem;
    padding-left: 2.618rem;
    padding-right: 2.618rem;
  }
}
